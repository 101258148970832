import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Toast, ToastContainer, Spinner } from 'react-bootstrap';
import { addCreditReseller, fetchRequestedCredits, fetchResellers } from "../../../store/resellerSlice";
import { useAuth } from "../../../context/AuthContext";
import { Formik, Form, Field } from "formik";
import { requestedAddCreditSchema } from "../../../schema/validationSchemas";
import { CheckCircle } from "react-feather";

const Credit = ({ show, handleClose, itemsPerPage, currentPage }) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarVariant, setSnackbarVariant] = useState('success');
  const dispatch = useDispatch();
  const { user } = useAuth();
  const resellers = useSelector(state => state.reseller.resellers); 

  const handleResellerChange = (event, setFieldValue) => {
    const resellerId = event.target.value;
    setFieldValue('resellerId', resellerId);

    const selectedReseller = resellers.find(reseller => reseller.id.toString() === resellerId);
    if (selectedReseller) {
      setFieldValue('companyId', selectedReseller.company_id);
    }
  };

  useEffect(() => {
    if (show) {
      dispatch(fetchResellers(
        itemsPerPage,
        currentPage,
      ));
    }
  }, [show, dispatch, user.id, itemsPerPage, currentPage]);

  return (
    <>
      <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1060 }}>
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
        >
         <Toast.Body style={{ color: "white", display: 'flex', alignItems: 'center' }}>
          <CheckCircle size={20} color="white" style={{ marginRight: 8 }} />
          {snackbarMessage}
        </Toast.Body>
        </Toast>
      </ToastContainer>
    
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Credit</Modal.Title>
        </Modal.Header>
        <Formik
            initialValues={{
              credits: '',
              price_per_credits: '',
              price: '',
              resellerId: '',
              companyId: '',  
            }}     
            validationSchema={requestedAddCreditSchema}
            onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
            const formData = new FormData();

          formData.append("credits", values.credits);
          formData.append("credit_rate", values.price_per_credits);
          formData.append("price", values.price);
          formData.append("company_id", values.companyId);
          formData.append("reseller_id", values.resellerId);
          formData.append("_method", "POST");
      
            dispatch(addCreditReseller(formData))
                .then((res) => {
                  if (res.payload && res.payload.success) {
                    dispatch(
                      fetchRequestedCredits(
                        itemsPerPage,
                        currentPage,
                      )
                    )
                    setSnackbarMessage(res.payload.message);
                    setSnackbarVariant("success");
                    setShowSnackbar(true);
                    resetForm();
                    handleClose();
                  } else {
                    setSnackbarMessage(res.payload.message);
                    setErrors(res.payload.data);
                    setSnackbarVariant('danger');
                    setShowSnackbar(true);
                  }
                })
                .catch((error) => {
                  setSnackbarMessage('Failed to add credit: ' + error.message);
                  setSnackbarVariant('danger');
                  setShowSnackbar(true);
                })
                .finally(() => {
                  setSubmitting(false);
                })
          }}
        >
          {({ values, setFieldValue, isSubmitting, errors, touched }) => (
            <Form encType="multipart/form-data">
              <Modal.Body>
              <div className="modal-body">
              {/* <div className="d-flex align-items-center gap-2 credit-visible mb-3">
                      <span><strong>Available Credits: </strong></span>
                      <p className="mb-0"> */}
                        {/* {userCredits} */}
                      {/* </p>
              </div> */}

                <label className="form-label"><b>Reseller</b></label>
                <Field as="select" name="resellerId" className="form-control" onChange={(event) => handleResellerChange(event, setFieldValue)}>
                    <option value="">Select Reseller</option>
                    {resellers?.map(reseller => (
                      <option key={reseller.id} value={reseller.id}>{reseller.name}</option>
                    ))}
                </Field>
                {errors.resellerId && touched.resellerId && <div className="text-danger">{errors.resellerId}</div>}

                <label className="form-label mt-5"><b>Credits</b></label>
                <Field
                  name="credits"
                  type="number"
                  placeholder=" "
                  className={`form-control ${errors?.credits && touched?.credits ? "is-invalid" : ""}`}
                  min="0"
                  onChange={e => {
                    setFieldValue('credits', e.target.value);
                    if (values.price_per_credits) {
                      setFieldValue('price', e.target.value * values.price_per_credits);
                    }
                  }}
                />
                {errors?.credits && touched?.credits && <div className="text-danger">{errors?.credits}</div>}
                <br/>
                <label className="form-label"><b>Price per credit</b></label>
                <div className="rupee-input-container mt-n3">
                  <span className="rupee-symbol" style={{position: "relative", bottom: "-33px", left: "6px"}}>₹</span>
                  <Field 
                    name="price_per_credits"
                    type="number"
                    step="0.01"
                    placeholder=" "
                    className={`form-control form-input ${errors.price_per_credits && touched.price_per_credits ? "is-invalid" : ""}`}
                    min="0"
                    onChange={e => {
                      setFieldValue('price_per_credits', e.target.value);
                      if (values.credits) {
                        setFieldValue('price', e.target.value * values?.credits);
                      }
                    }}
                  />
                </div>
                {errors.price_per_credits && touched.price_per_credits && <div className="text-danger">{errors.price_per_credits}</div>}
                <div className="my-4">
                    <strong className="pe-2">Total Credit Price: </strong>
                    {values?.credits && values.price_per_credits ? "₹"  +  (values?.credits * values.price_per_credits).toFixed(2) : '₹0'}
                </div>
              </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button type="submit" variant="primary" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <>
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                      Adding...
                    </>
                  ) : (
                    "Add"
                  )}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default Credit;