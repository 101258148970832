import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosapi from "../utilities/axiosApi";

export const fetchResellers = createAsyncThunk(
  "reseller/fetchResellers",
  async ({ offset, limit, search, sort_by_key, sort_by_value }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/admin/reseller", {
        params: { offset, limit, search, sort_by_key, sort_by_value },
      });
      
      return {
        data: response.data.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages,
      };
    } catch (err) {
      console.error("GET API Error:", err); 
      return rejectWithValue(err.response.data);
    }
  }
);

export const addReseller = createAsyncThunk(
  "reseller/addReseller",
  async (resellerData, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post("/api/admin/reseller", resellerData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateReseller = createAsyncThunk(
  "reseller/updateReseller",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post(`/api/admin/reseller/${data.get('id')}`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteReseller = createAsyncThunk(
  "reseller/deleteReseller",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosapi.delete(`/api/admin/reseller/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
    }
);

export const addCreditReseller = createAsyncThunk(
  'reseller/addCreditReseller',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post(`/api/admin/reseller-credit`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchRequestedCredits = createAsyncThunk(
  'reseller/fetchRequestedCredits',
  async ({ offset, limit, search, sort_by_key, sort_by_value, status }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/admin/reseller-credit", {
        params: { offset, limit, search, sort_by_key, sort_by_value, status: status },
      });
      
      return {
        data: response.data.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages,
      };
    } catch (err) {
      console.error("GET API Error:", err); 
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchResellerCreditDetail = createAsyncThunk(
  "reseller/fetchResellerCreditDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/admin/reseller-credit-details/" + id);
      return response.data.data;
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCustomerCreditDetail = createAsyncThunk(
  "reseller/fetchCustomerCreditDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/admin/customer-credit-details/" + id);
      return response.data.data;
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchResellerDetail = createAsyncThunk(
  "reseller/fetchResellerDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/admin/reseller/" + id);
      return response.data.data;
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCustomerDetail = createAsyncThunk(
  "reseller/fetchCustomerDetail",
  async (id, {rejectWithValue}) => {
    try {
      const response = await axiosapi.get("/api/admin/customer/" + id);
      return response.data.data;
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
)

export const fetchClientDetail = createAsyncThunk(
  "reseller/fetchClientDetail",
  async (id, {rejectWithValue}) => {
    try {
      const response = await axiosapi.get("/api/admin/client-event/" + id, );
      return response.data.data;
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
)

export const fetchCustomerDetails = createAsyncThunk(
  "customer/fetchCustomerDetails",
  async ({ id, offset, limit, search, sort_by_key, sort_by_value }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/admin/customers/" + id, {
        params: { id, offset, limit, search, sort_by_key, sort_by_value },
      });
      return {
        data: response.data.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages
      };
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCustomerEvents = createAsyncThunk(
  "customer/fetchCustomerEvents",
  async ({ id, offset, limit, search, sort_by_key, sort_by_value }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/admin/customer-events/" + id, {
        params: { id, offset, limit, search, sort_by_key, sort_by_value },
      });

      return {
        data: response.data.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages
      };
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCampaignDetails = createAsyncThunk(
  "campaign/fetchCampaignDetails",
  async ({ id, offset, limit, search, sort_by_key, sort_by_value, status }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/admin/customer-campaigns/" + id, {
        params: { id, offset, limit, search, sort_by_key, sort_by_value, status: status === "all"? "": status },
      });
      return {
        data: response.data.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages
      };
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGuestDetails = createAsyncThunk( 
  "guest/fetchGuestDetails",
  async ({ id, offset, limit, search, sort_by_key, sort_by_value, is_test, status }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/admin/campaign-guests/" + id, {
        params: { id, offset, limit, search, sort_by_key, sort_by_value, is_test, status },
      });
      return {
        data: response.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages
      };
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchResellerCredits = createAsyncThunk(
  "reseller/fetchResellerCredits",
  async ({ id, offset, limit, search, sort_by_key, sort_by_value, status }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/admin/reseller-credits/" + id , {
        params: { id, offset, limit, search, sort_by_key, sort_by_value, status: status },
      });
      return {
        data: response.data.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages
      };
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchResellerCreditRequest = createAsyncThunk(
  "reseller/fetchResellerCredits",
  async ({ id, offset, limit, search, sort_by_key, sort_by_value }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/admin/reseller-credit/" + id , {
        params: { id, offset, limit, search, sort_by_key, sort_by_value },
      });
      return {
        data: response.data.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages
      };
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCustomerCredits = createAsyncThunk(
  "customer/fetchCustomerCredits",
  async ({ id, offset, limit, search, sort_by_key, sort_by_value }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/admin/customer-credits/" + id, {
        params: { id, offset, limit, search, sort_by_key, sort_by_value },
      });
      
      return {
        data: response.data.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages
      };
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCampaignDetail = createAsyncThunk(
  "campaign/fetchCampaignDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/admin/campaign/" + id);
      return response.data.data;
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
)

export const fetchState = createAsyncThunk(
  "reseller/state",
  async ()=>{
    try {
      const response = await axiosapi.get("/api/states");
      return response.data.data;
    } catch (err) {
      console.error("GET API Error:", err);
      return err.response.data;
    }
  }
)

export const fetchCountry = createAsyncThunk(
  "reseller/fetchCountry",
  async () => {
    try {
      const response = await axiosapi.get("/api/countries");
      return response.data.data;
    } catch (err) {
      console.error("GET API Error:", err);
      return err.response.data;
    }
  }
);

export const fetchCountryByCode = createAsyncThunk(
  "reseller/fetchCountryByCode",
  async (countryCode, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post("/api/countries-data", {
        search: countryCode
      });
      return response.data.data;
    } catch (err) {
      console.error("POST API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCitiesByStateId = createAsyncThunk(
  "reseller/fetchCitiesByStateId",
  async (state_id, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post("/api/cities", { state_id: state_id });
      return response.data.data;
    } catch (err) {
      console.error("POST API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const toggleResellerActiveStatus = createAsyncThunk(
  "reseller/toggleResellerActiveStatus",
  async ({ id, isActive }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post(`/api/admin/change-active-status/${id}`, {
        is_active: isActive
      });
      return response.data;  
    } catch (err) {
      console.error("POST API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCreditLogBooks = createAsyncThunk(
  "reseller/fetchCreditLogBooks",
  async ({ reseller_id, offset, limit, sort_by_key, sort_by_value, start_date, end_date }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/admin/credit-logbooks", {
        params: { reseller_id, offset, limit, sort_by_key, sort_by_value, start_date, end_date },
      });
      return {
        data: response.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages
      };
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCustomerCreditLogBooks = createAsyncThunk(
  "reseller/fetchCustomerCreditLogBooks",
  async ({ customer_id, offset, limit, sort_by_key, sort_by_value, start_date, end_date }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/admin/customer-credit-logbooks", {
        params: { customer_id, offset, limit,   sort_by_key, sort_by_value, start_date, end_date },
      });
      return {
        data: response.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages
      };
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSettings = createAsyncThunk(
  "reseller/updateSettings",
  async (settingsData, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post("/api/admin/settings/update", {
        settings: settingsData
      });
      return response.data;
    } catch (err) {
      console.error("POST API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchSettings = createAsyncThunk(
  "reseller/fetchSettings",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/admin/settings");
      return response.data;
    } catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const addCreditRequestAction= createAsyncThunk(
  "reseller/addCreditRequestAction",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post("/api/admin/credit-request-action", data);
      return response.data;
    } catch (err) {
      console.error("POST API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const userDeleteLogo = createAsyncThunk(
  'auth/userDeleteLogo',
  async ({id}, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post(`/api/admin/delete-company-logo/${id}`);
      return response.data; 
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ message: 'Network error' });
      }
    }
  }
);

export const fetchInquiries = createAsyncThunk(
  "reseller/fetchInquiries",
  async ({offset, limit, search}, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/admin/inquiries", {params: {offset, limit, search}});
      return response.data;
    } catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

const resellerSlice = createSlice({
  name: "reseller",
  initialState: {
    resellers: [],
    currentReseller: {},
    customer: [],
    currentCustomer: {},
    customerEvents: [],
    currentClient:{},
    credits: [],
    customerCredits: [],
    guests:[],
    currentCampaign:{},
    countries: [],
    states: [],
    campaigns:[],
    creditResellers:[],
    currentCreditDetail:{},
    currentCustomerCreditDetail:{},
    creditLogBooks:{},
    customerCreditLogBooks:{},
    settings: {},
    total: 0,
    page: 1,
    limit: 10,
    status: 'idle',
    error: null,
    totalPages: 0,
    requestedCredits:[],
    inquiries: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchResellers.fulfilled, (state, action) => {
        state.resellers = action.payload.data || []; 
        state.total = action.payload.total || 0; 
        state.page = action.payload.offset;
        state.limit = action.payload.limit;
        state.totalPages = action.payload.totalPages;
        state.search = action.payload.search;
      })
      .addCase(fetchResellerDetail.fulfilled, (state, action) => {
        state.currentReseller = action.payload;
      })
      .addCase(fetchCustomerDetails.fulfilled, (state, action) => {
        state.customer= action.payload;
        state.total = action.payload.total || 0; 
        state.page = action.payload.offset;
        state.limit = action.payload.limit;
        state.totalPages = action.payload.totalPages;
        state.search = action.payload.search;
      })
      .addCase(fetchCustomerEvents.fulfilled, (state, action) => {
        state.customerEvents = action.payload;
        state.total = action.payload.total || 0;
        state.page = action.payload.offset;
        state.limit = action.payload.limit;
        state.totalPages = action.payload.totalPages;
        state.search = action.payload.search;
      })
      .addCase(fetchCustomerDetail.fulfilled, (state, action) => {
        state.currentCustomer = action.payload;
      })
      .addCase(fetchClientDetail.fulfilled, (state, action) => {
        state.currentClient = action.payload;
      })
      .addCase(fetchCampaignDetail.fulfilled, (state, action) => {
        state.currentCampaign = action.payload;
      })
      .addCase(fetchResellerCredits.fulfilled, (state, action) => {
        state.credits = action.payload;
        state.total = action.payload.total || 0;
        state.page = action.payload.offset;
        state.limit = action.payload.limit;
        state.totalPages = action.payload.totalPages;
        state.search = action.payload.search;
      })
      .addCase(fetchCustomerCredits.fulfilled, (state, action) => {
        state.customerCredits = action.payload;
        state.total = action.payload.total || 0;
        state.page = action.payload.offset;
        state.limit = action.payload.limit;
        state.totalPages = action.payload.totalPages;
        state.search = action.payload.search;
      })
      .addCase(fetchCampaignDetails.fulfilled, (state, action) => {
        state.campaigns = action.payload;
        state.total = action.payload.total || 0;
        state.page = action.payload.offset;
        state.limit = action.payload.limit;
        state.totalPages = action.payload.totalPages;
        state.search = action.payload.search;
      })
      .addCase(fetchGuestDetails.fulfilled, (state, action) => {
        state.guests = action.payload.data.data;
        state.total = action.payload.total || 0;
        state.page = action.payload.offset;
        state.limit = action.payload.limit;
        state.totalPages = action.payload.totalPages;
        state.search = action.payload.search;
      })
      .addCase(fetchCreditLogBooks.fulfilled, (state, action) => {
        state.creditLogBooks = action.payload.data;
        state.total = action.payload.total || 0;
        state.page = action.payload.offset;
        state.limit = action.payload.limit;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(fetchCustomerCreditLogBooks.fulfilled, (state, action) => {
        state.customerCreditLogBooks = action.payload.data;
      })
      .addCase(updateSettings.fulfilled, (state, action) => {
        state.settings = action.payload.data;
      })
      .addCase(fetchCountry.fulfilled, (state, action) => {
        state.countries = action.payload;
      }) 
      .addCase(fetchState.fulfilled, (state, action) => {
        state.states = action.payload;
      })  
      .addCase(addCreditReseller.fulfilled, (state, action) => {
        if (action.payload && action.payload.success) {
          state.creditResellers.push(action.payload.data);
          state.total += 1;
        }
      })
      .addCase(fetchResellerCreditDetail.fulfilled, (state, action) => {
        state.currentCreditDetail = action.payload;
      })
      .addCase(fetchCustomerCreditDetail.fulfilled, (state, action) => {
        state.currentCustomerCreditDetail = action.payload;
      })
      .addCase(toggleResellerActiveStatus.fulfilled, (state, action) => {
        const index = state.resellers.findIndex((reseller) => reseller.id === action.payload.id);
        if (index !== -1) {
          state.resellers[index] = {...state.resellers[index], ...action.payload};
        }
      })
      .addCase(addReseller.fulfilled, (state, action) => {
        if (action.payload && action.payload.success) {
          state.resellers.push(action.payload.data);
          state.total += 1;
        }
      })
      .addCase(updateReseller.fulfilled, (state, action) => {
        const index = state.resellers.findIndex((reseller) => reseller.id === action.payload.id);
        if (index !== -1) {
          state.resellers[index] = {...state.resellers[index], ...action.payload};
        }
      })
      .addCase(deleteReseller.fulfilled, (state, action) => {
        state.resellers = state.resellers.filter(
          (r) => r.id !== action.payload.id
        );
        state.total -= 1;
      })
      .addCase(fetchRequestedCredits.fulfilled, (state, action) => {
        state.requestedCredits = action.payload;
      })
      .addCase(addCreditRequestAction.fulfilled, (state, action) => {
        state.requestedCredits = action.payload.data;
      })
      .addCase(userDeleteLogo.fulfilled, (state, action) => {
        state.userLogo = action.payload.data;
      })
      .addCase(fetchInquiries.fulfilled, (state, action) => {
        state.inquiries = action.payload;
      });
  },
});

export default resellerSlice.reducer;
