import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Button,
  Toast,
  Nav,
  Tab,
  ToastContainer,
  Spinner,
  InputGroup,
  Row,
  Col,
} from "react-bootstrap";
// import Form from "react-bootstrap/Form";
import { Formik, Field, Form, ErrorMessage } from "formik";
// Form,
import {
  X,
  Eye,
  EyeSlash,
  ArrowUp,
  CheckCircle,
} from "../../../constant/icons/icons";
import { resellertabcontent } from "../../../constant/reseller/reseller";
import {
  addReseller,
  fetchResellers,
  fetchState,
  fetchCitiesByStateId
} from "../../../store/resellerSlice";
import { XCircle } from "react-feather";
// import { resellerSchema } from "../../../schema/validationSchemas";

export default function Add({ show, handleClose }) {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [key, setKey] = useState("Company Detail");
  const [resellerDetailEnabled, setResellerDetailEnabled] = useState(false); 
  // const [otherDetailsEnabled, setOtherDetailsEnabled] = useState(false); 
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [formResellerValid, setFormResellerValid] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [isLogoUpdated, setIsLogoUpdated] = useState(false);
  const [logoName, setLogoName] = useState("");
  const [cities, setCities] = useState([]);
  const [isCityDropdownEnabled, setIsCityDropdownEnabled] = useState(false);
  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const [imageError, setImageError] = useState("");

  const states = useSelector((state) => state.reseller.states);
  const initialResellerState = {
    name:"",
    username: "",
    state_id: "",
    city_id: "",
    password: "",
    password_confirmation: "",

    company_name: "",
    company_mobile_number: "",
    email: "",
    company_short_id: "",
    company_domain_url: "",
    company_website: "",
    company_address: "",
    company_logo: "",
  };

  // useEffect(() => {
  //   dispatch(fetchCountry());
  // }, [dispatch]);

  useEffect(() => {
    dispatch(fetchState());  // Assume fetchStates is an action to fetch the states list
  }, [dispatch]);
  
  // Then in your form, map the states to dropdown options
  
  const submitFormRef = useRef(null);

  // const handleCountryCodeChange = async (code, setFieldValue) => {
  //   const action = await dispatch(fetchCountryByCode(code));
  //   if (action.payload && action.payload.length > 0) {
  //     const country = action.payload.find(
  //       (country) => country.phone_code === code
  //     );
  //     const countryId = country ? country.id : null;
  //     if (countryId) {
  //       setFieldValue("country_id", countryId);
  //     } else {
  //       setFieldValue("country_id", "");
  //     }
  //   } else {
  //     setFieldValue("country_id", "");
  //   }
  // };

  // const handleCountryNameChange = (selectedCountryId, setFieldValue) => {
  //   const country = countries.find(
  //     (country) => Number(country.id) === Number(selectedCountryId)
  //   );
  //   const phoneCode = country ? country?.phone_code : null;
  //   if (phoneCode) {
  //     setFieldValue("country_code", phoneCode);
  //   }
  // };


const handleModalClose = useCallback(() => {
  setKey("Company Detail"); 
  setResellerDetailEnabled(false); 
  // setOtherDetailsEnabled(false); 
  setShowPassword(true);
  setShowConfirmPassword(true);
  setFormValid(false);
  setFormResellerValid(false);
  setIsCityDropdownEnabled(false);
  setIsLogoUpdated(false);
  setLogoName("");
  handleClose();
}, [handleClose]);

// const handleImageChange = (event, setFieldValue) => {
//   const file = event.currentTarget.files[0];
//   if (file) {
//     setFieldValue("company_logo", file);
//     setLogoName(file.name);  
//     setIsLogoUpdated(true);
//     const reader = new FileReader();
//     reader.onloadend = () => {
//       setFieldValue("imagePreview", reader.result);
//     };
//     reader.readAsDataURL(file);
//   } else {
//     setFieldValue("company_logo", "");
//     setLogoName("");  
//     setIsLogoUpdated(true);
//   }
// };

const handleImageChange = (event, setFieldValue, setFieldError, setFieldTouched) => {
  const file = event.currentTarget.files[0];
  if (file) {
    setLogoName(file.name); 
    if (!file.type.startsWith('image/')) {
      if (file.type === 'video/mp4') {
        const errorMsg = "This file is unsupported. Please upload an image file.";
        setImageError(errorMsg);
        setFieldError("company_logo", errorMsg);
        setFieldTouched("company_logo", true, false);
      } else {
        const errorMsg = "Invalid file type. Only images are supported.";
        setImageError(errorMsg);
        setFieldError("company_logo", errorMsg);
        setFieldTouched("company_logo", true, false);
      }
      return; 
    }
    setIsLogoUpdated(true);
    setImageError("");
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        if (img.width > 250 || img.height > 150) {
          const errorMsg = "The logo must not exceed 250 pixels in width and 150 pixels in height.";
          setImageError(errorMsg); 
          setFieldError("company_logo", errorMsg);
          setFieldTouched("company_logo", true, false);
        } else {
          setImageError(""); 
          setFieldError("company_logo", "");
          setFieldTouched("company_logo", true, false);
        }
        setFieldValue("imagePreview", reader.result);
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
    setFieldValue("company_logo", file);
    setFieldTouched("company_logo", true, false);
  } else {
    setFieldValue("company_logo", "");
    setFieldValue("imagePreview", "");
    setLogoName("");
    setIsLogoUpdated(false);
    setFieldError("company_logo", "");
    setFieldTouched("company_logo", false, false);
    setImageError("");
  }
};
 
  const handleResellerValidation = () => {
    if (formValid) { 
      setKey("Reseller Detail");
    }
  };

  const handleOtherValidation = () => {
    if (formResellerValid) { 
      setKey("Other Detail");
    }
  };

  const handleStateChange = async (stateId, setFieldValue) => {
    setFieldValue("state_id", stateId);
    setFieldValue("city_id", ""); 
    if (stateId) {
      const action = await dispatch(fetchCitiesByStateId(stateId));
      if (action.payload) {
        setCities(action.payload);
        setIsCityDropdownEnabled(true); 
      } else {
        setCities([]);
        setIsCityDropdownEnabled(false); 
      }
    } else {
      setCities([]);
      setIsCityDropdownEnabled(false); 
    }
  };

  const validateForm = (values) => {
    const errors = {};
    // Custom validations for each field
    if (!values.name) {
      errors.name = 'Name is required';
    } else {
      if (values.name.length < 2) {
        errors.name = 'Name should be at least 2 characters';
      } else if (values.name.length > 50) {
        errors.name = 'Name should not exceed 50 characters';
      }
      if (!/^[a-zA-Z\s\-']+$/.test(values.name)) {
        errors.name = 'Name should not contain numbers or special characters except space, dash, and apostrophe';
      }
    }
  
    // Username validation
    if (!values.username) {
      errors.username = 'Login ID is required';
    }
  
    // State ID validation
    if (!values.state_id) {
      errors.state_id = 'State is required';
    }
  
    // City ID validation
    if (!values.city_id) {
      errors.city_id = 'City is required';
    }
  
    // Password validation
    if (!values.password) {
      errors.password = 'Password is required';
    } else {
      if (values.password.length < 8) {
        errors.password = 'Minimum password length is 8';
      } else if (values.password.length > 30) {
        errors.password = 'Maximum password length is 30';
      }
    }
  
    // Password confirmation validation
    if (!values.password_confirmation) {
      errors.password_confirmation = 'Confirm password is required';
    } else if (values.password_confirmation !== values.password) {
      errors.password_confirmation = 'Passwords must match';
    }
  
    // Company name validation
    if (!values.company_name) {
      errors.company_name = 'Company name is required';
    }
  
    // Company mobile number validation
    if (!values.company_mobile_number) {
      errors.company_mobile_number = 'Company mobile number is required';
    } else if (!/^\d{10}$/.test(values.company_mobile_number)) {
      errors.company_mobile_number = 'Please, Enter 10 digits Mobile number';
    }
  
    // Email validation
    if (!values.email) {
      errors.email = 'Company email is required';
    } else if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/.test(values.email)) {
      errors.email = 'Invalid email format';
    }
  
    // Company short ID validation
    if (!values.company_short_id) {
      errors.company_short_id = 'Company short id is required';
    }
  
    // Company domain URL validation
    // if (!values.company_domain_url) {
    //   errors.company_domain_url = 'Company domain URL is required';
    // } else 
    // 
    if (values.company_domain_url) {
      if (!/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(values.company_domain_url)) {
        errors.company_domain_url = 'Invalid domain URL format: example.com';
      }
    }
    // Determine overall form validity for each tab
    const isResellerDetailsValid = !errors.name && !errors.username && !errors.password && !errors.password_confirmation; // Check based on all fields in this tab
    const isCompanyDetailsValid = !errors.company_name && !errors.state_id && !errors.city_id && !errors.company_mobile_number  && !errors.email && !errors.company_short_id && !errors.company_domain_url; // Check based on all fields in this tab
  
    // Update state based on tab validity
    // setFormResellerValid(isResellerDetailsValid);
    // setFormValid(isCompanyDetailsValid);
    // setResellerDetailEnabled(isResellerDetailsValid);

    if (formValid !== isCompanyDetailsValid) {
      setFormValid(isCompanyDetailsValid);
      setResellerDetailEnabled(isCompanyDetailsValid);
    }
    if(formResellerValid !== isResellerDetailsValid) {
      setFormResellerValid(isResellerDetailsValid);
    }
  
    return errors;
  };

  const handleRemoveLogo = () => {
    setIsLogoUpdated(false);
    setLogoName("");
  };
  
  
  return (
    <>
      <ToastContainer position="top-end" className=" pt-3 pe-3 cu-toster">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
        >
          <Toast.Body className="d-flex align-items-center gap-2 text-white">
            <CheckCircle size={22} color="#ffffff" weight="duotone" />
            <p className="mb-0">{snackbarMessage}</p>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Modal
        show={show}
        onHide={handleModalClose}
        dialogClassName="modal-dialog-scrollable add-reseller-popup reseller-popup-size"
        centered
      >
        <Modal.Header className="add-reseller-header d-inline-block p-0 border-bottom-0">
          <div className="d-flex align-items-center justify-content-between gap-3 br-bottom py-3 px-4">
            <h5 className="fw-semibold mb-0">Add Reseller</h5>
            <div className="cursor-pointer rounded-circle d-flex align-items-center justify-content-center x-arrow">
              <X size={18} color="" weight="bold" onClick={handleModalClose} />
            </div>
          </div>
          <div className="mx-4 py-3">
            <Tab.Container
              id="left-tabs-example"
              className="px-4"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Nav
                variant="tabs"
                className="border-0 d-flex align-items-center gap-3 custom-tab "
              >
                {resellertabcontent.map((tab, index) => (
                  <Nav.Item key={index} className="cu-tab-item ">
                    <Nav.Link
                      eventKey={tab.title}
                      className={`border rounded-2 d-flex align-items-center gap-2 ${
                        (tab.title === "Reseller Detail" && !resellerDetailEnabled) || (tab.title === "Other Detail" &&!formResellerValid)
                          ? "disabled"
                          : ""
                      }`}
                      disabled={
                        (tab.title === "Reseller Detail" && !resellerDetailEnabled) || (tab.title === "Other Detail" &&!formResellerValid)
                      }
                    >
                      <span>{tab.icon}</span>
                      <p className="mb-0">{tab.title}</p>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Tab.Container>
          </div>
        </Modal.Header>
        <Modal.Body className="add-reseller-body">
          <Formik
            initialValues={initialResellerState}
            // validationSchema={resellerSchema}
            validate={validateForm}
            onSubmit={async (values, { resetForm, setErrors }) => {
              setIsSubmitting(true);
              const formData = new FormData();
              Object.keys(values).forEach((key) => {
                if (key === "company_logo" && isLogoUpdated) {
                  formData.append("company_logo", values[key]);
                } else {
                  formData.append(key, values[key] ? values[key] : "");
                }
              });
              try {
                const res = await dispatch(addReseller(formData));
                if (res.payload && res.payload.success) {
                  dispatch(
                    fetchResellers({
                      offset: 0,
                      limit: itemsPerPage,
                      search: "",
                    })
                  );
                  setSnackbarMessage(res.payload.message);
                  setSnackbarVariant("success");
                  setShowSnackbar(true);
                  resetForm();
                  handleModalClose();
                } else {
                  setSnackbarMessage(res.payload.message);
                  setErrors(res.payload.data);
                  setSnackbarVariant("danger");
                  setShowSnackbar(true);
                  setKey("Other Detail");
                }
              } catch (error) {
                setSnackbarMessage("Failed to add reseller: " + error.message);
                setSnackbarVariant("danger");
                setShowSnackbar(true);
                setKey("Other Detail");
              } finally {
                setIsSubmitting(false);
              }
              setFormValid(false);
            }}
          >
            {({
              submitForm,
              handleChange,
              setFieldValue,
              errors,
              touched,
              setFieldError,
              setFieldTouched
            }) => {
              submitFormRef.current = submitForm;
              return (
                <Form encType="multipart/form-data" className="">
                  <Tab.Content className="pb-3 pt-0">
                    {key === "Reseller Detail" && (
                      <>
                        <Row className="g-3">
                          <Col xl={12} lg={12} md={12} xs={12}>
                            <label className="form-label ms-1">Person Name</label>
                            <Field
                              name="name"
                              type="text"
                              className={`form-control shadow-none ${
                                errors.name && touched.name ? "is-invalid" : ""
                              }`}
                              placeholder="Enter Person Name"
                              onChange={handleChange}
                            />
                            {errors.name && touched.name ? (
                              <div className="invalid-feedback ms-1 d-block">
                                {errors.name}
                              </div>
                            ) : null}
                          </Col>
                          <Col xl={12} lg={12} md={12} xs={12}>
                            <label className="form-label ms-1">Login ID(Username)</label>
                            <Field
                              name="username"
                              type="text"
                              className={`form-control shadow-none ${
                                errors.username && touched.username ? "is-invalid" : ""
                              }`}
                              placeholder="Enter Login ID"
                              onChange={handleChange}
                            />
                            {errors.username && touched.username ? (
                              <div className="invalid-feedback ms-1 d-block">
                                {errors.username}
                              </div>
                            ) : null}
                          </Col>
                         
                          {/* <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                            <label className="form-label ms-1">
                              Country Code
                            </label>
                            <Field
                              name="country_code"
                              type="text"
                              className={`form-control shadow-none ${
                                errors.country_code && touched.country_code
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Enter country code"
                              onChange={(e) => {
                                handleChange(e);
                                handleCountryCodeChange(
                                  e.target.value,
                                  setFieldValue
                                );
                              }}
                            />
                            {errors.country_code && touched.country_code ? (
                              <div className="invalid-feedback ms-1 d-block">
                                {errors.country_code}
                              </div>
                            ) : null}
                          </Col>
                          <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                            <label className="form-label ms-1">Country</label>
                            <Field
                              as="select"
                              name="country_id"
                              className={`form-control shadow-none ${
                                errors.country_id && touched.country_id
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onChange={(e) => {
                                handleChange(e);
                                handleCountryNameChange(
                                  e.target.value,
                                  setFieldValue
                                );
                              }}
                            >
                              <option value="">Select Country</option>
                              {countries.map((country) => (
                                <option key={country.id} value={country.id}>
                                  {country.name}
                                </option>
                              ))}
                            </Field> */}

                            {/* -----------------<Form.Select
                              name="country_id"
                              className={`form-control shadow-none ${
                                errors.country_id && touched.country_id
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onChange={(e) => {
                                handleChange(e);
                                handleCountryNameChange(
                                  e.target.value,
                                  setFieldValue
                                );
                              }}
                              aria-label="Default select example"
                            >
                              <option value="">Select Country</option>
                              {countries.map((country) => (
                                <option key={country.id} value={country.id}>
                                  {country.name}
                                </option>
                              ))}
                            </Form.Select> */}
                            {/* {errors.country_id && touched.country_id ? (
                              <div className="invalid-feedback ms-1 d-block">
                                {errors.country_id}
                              </div>
                            ) : null}
                          </Col> */}
                          {/* <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                            <label className="form-label ms-1">
                              Mobile Number
                            </label>
                            <Field
                              name="mobile_number"
                              type="text"
                              className={`form-control shadow-none ${
                                errors.mobile_number && touched.mobile_number
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Enter mobile number"
                              onChange={handleChange}
                            />
                            {errors.mobile_number && touched.mobile_number ? (
                              <div className="invalid-feedback ms-1 d-block">
                                {errors.mobile_number}
                              </div>
                            ) : null}
                          </Col> */}
                          {/* <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                            <label className="form-label ms-1">Email</label>
                            <Field
                              name="email"
                              type="email"
                              className={`form-control shadow-none ${
                                errors.email && touched.email
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Enter email"
                              onChange={handleChange}
                            />
                            {errors.email && touched.email ? (
                              <div className="invalid-feedback ms-1 d-block">
                                {errors.email}
                              </div>
                            ) : null}
                          </Col> */}
                          <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                            <label className="form-label ms-1">Password</label>
                            <InputGroup className="shadow-none">
                              <Field
                                name="password"
                                type={showPassword ? "password" : "text"}
                                autoComplete="new-password"
                                className={`form-control shadow-none ${
                                  errors.password && touched.password
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Password"
                                onChange={handleChange}
                              />
                              <InputGroup.Text
                                className="shadow-none d-flex align-items-center justify-content-center eye-spacing"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <EyeSlash
                                    size={19}
                                    color="#637381"
                                    weight="duotone"
                                    className="cursor-pointer"
                                  />
                                ) : (
                                  <Eye
                                    size={19}
                                    color="#637381"
                                    weight="duotone"
                                    className="cursor-pointer"
                                  />
                                )}
                              </InputGroup.Text>
                            </InputGroup>
                            <ErrorMessage
                              component="div"
                              className="invalid-feedback ms-1 d-block"
                              name="password"
                            />
                          </Col>
                          <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                            <label className="form-label ms-1">
                              Confirm Password
                            </label>
                            <InputGroup className="shadow-none">
                              <Field
                                name="password_confirmation"
                                type={showConfirmPassword ? "password" : "text"}
                                autoComplete="new-password"
                                className={`form-control shadow-none ${
                                  errors.password_confirmation &&
                                  touched.password_confirmation
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Confirm Password"
                                onChange={handleChange}
                              />
                              <InputGroup.Text
                                className="shadow-none d-flex align-items-center justify-content-center eye-spacing"
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                              >
                                {showConfirmPassword ? (
                                  <EyeSlash
                                    size={19}
                                    color="#637381"
                                    weight="duotone"
                                    className="cursor-pointer"
                                  />
                                ) : (
                                  <Eye
                                    size={19}
                                    color="#637381"
                                    weight="duotone"
                                    className="cursor-pointer"
                                  />
                                )}
                              </InputGroup.Text>
                            </InputGroup>
                            <ErrorMessage
                              component="div"
                              className="invalid-feedback ms-1 d-block"
                              name="password_confirmation"
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                    {key === "Company Detail" && (
                      <>
                        <Row className="g-3">
                          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <label className="form-label ms-1">
                              Company Name
                            </label>
                            <Field
                              name="company_name"
                              type="text"
                              className={`form-control shadow-none ${
                                errors.company_name && touched.company_name
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Enter Company Name"
                              onChange={handleChange}
                            />
                            {errors.company_name && touched.company_name ? (
                              <div className="invalid-feedback ms-1 d-block">
                                {errors.company_name}
                              </div>
                            ) : null}
                          </Col>

                          <Col xl={6} lg={6} md={6} xs={6}>
                            <label className="form-label ms-1">State</label>
                            <Field as="select" name="state_id" className={`form-control shadow-none ${errors.state_id && touched.state_id ? "is-invalid" : ""}`}onChange={(e) => handleStateChange(e.target.value, setFieldValue)}>
                              <option value="">Select State</option>
                              {states.map((state) => (
                                <option key={state.id} value={state.id}>{state.name}</option>
                              ))}
                            </Field>
                            {errors.state_id && touched.state_id && (
                              <div className="invalid-feedback ms-1 d-block">
                                {errors.state_id}
                              </div>
                            )}
                          </Col>
                          <Col xl={6} lg={6} md={6} xs={6}>
                            <label className="form-label ms-1">City</label>
                            <Field name="city_id" as="select" className={`form-control shadow-none ${errors.city_id && touched.city_id ? "is-invalid" : ""}`}  disabled={!isCityDropdownEnabled} >
                            <option value="">Select City</option>
                                {cities.map((city) => (
                                  <option key={city.id} value={city.id}>{city.name}</option>
                                ))}
                            </Field>
                            {errors.city_id && touched.city_id && (
                              <div className="invalid-feedback ms-1 d-block">
                                {errors.city_id}
                              </div>
                            )}
                          </Col>

                          <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                            <label className="form-label ms-1">
                              Mobile Number
                            </label>
                            <Field
                              name="company_mobile_number"
                              type="text"
                              className={`form-control shadow-none ${
                                errors.company_mobile_number && touched.company_mobile_number
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Enter Mobile Number"
                              onChange={handleChange}
                            />
                            {errors.company_mobile_number && touched.company_mobile_number ? (
                              <div className="invalid-feedback ms-1 d-block">
                                {errors.company_mobile_number}
                              </div>
                            ) : null}
                          </Col>

                          <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                            <label className="form-label ms-1">Email</label>
                            <Field
                              name="email"
                              type="email"
                              className={`form-control shadow-none ${
                                errors.email && touched.email
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Enter Email"
                              onChange={handleChange}
                            />
                            {errors.email && touched.email ? (
                              <div className="invalid-feedback ms-1 d-block">
                                {errors.email}
                              </div>
                            ) : null}
                          </Col>
                          <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                            <label className="form-label ms-1">Short ID</label>
                            <Field
                              name="company_short_id"
                              type="id"
                              className={`form-control shadow-none ${
                                errors.company_short_id && touched.company_short_id
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Enter Short ID"
                              onChange={handleChange}
                            />
                            {errors.company_short_id && touched.company_short_id ? (
                              <div className="invalid-feedback ms-1 d-block">
                                {errors.company_short_id}
                              </div>
                            ) : null}
                          </Col>

                          <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                          <label className="form-label ms-1">
                            Domain URL
                            <span style={{ color: "grey", fontSize: "smaller", marginLeft: "5px" }}>
                                (Optional)
                              </span>
                          </label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text>https://</InputGroup.Text>
                            <Field
                              name="company_domain_url"
                              type="text"
                              className={`form-control shadow-none ${
                                errors.company_domain_url && touched.company_domain_url
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Enter Domain URL"
                              onChange={(e) => {
                                const newValue = `${e.target.value}`;
                                setFieldValue("company_domain_url", newValue, true);
                              }}
                            />
                          </InputGroup>
                          {errors.company_domain_url && touched.company_domain_url && (
                            <div className="invalid-feedback d-block">
                              {errors.company_domain_url}
                            </div>
                          )}
                        </Col>


                          {/* <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <label className="form-label ms-1">
                              Company Address
                            </label>
                            <Field
                              name="company_address"
                              type="text"
                              className={`form-control shadow-none ${
                                errors.company_address &&
                                touched.company_address
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Enter address"
                              onChange={handleChange}
                            />
                            {errors.company_address &&
                            touched.company_address ? (
                              <div className="invalid-feedback ms-1 d-block">
                                {errors.company_address}
                              </div>
                            ) : null}
                          </Col>

                          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                          <label className="form-label ms-1">
                              Upload Company Logo   
                                <span style={{color: "grey", fontSize: "smaller", marginLeft:"5px"}}>
                                  (The logo must not exceed 250 pixels in width and 150 pixels in height.)
                                </span>
                            </label>

                            <div className=" position-relative">
                              <div>
                                <InputGroup className="shadow-none">
                                  <Field
                                    id="company_logo"
                                    name="company_logo"
                                    type="text"
                                    readOnly
                                    className={`form-control shadow-none ${
                                      errors.company_logo && touched.company_logo
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    placeholder="Choose File"
                                  />
                                  <InputGroup.Text className="shadow-none d-flex align-items-center justify-content-center upload-ico">
                                    <ArrowUp
                                      size={19}
                                      color="#637381"
                                      weight="bold"
                                      className="cursor-pointer"
                                    />
                                  </InputGroup.Text>
                                </InputGroup>
                              </div>
                              <div className=" position-absolute top-0 start-0 end-0  bottom-0 opacity-0">
                                <input
                                  type="file"
                                  id="company_logo"
                                  name="company_logo"
                                  onChange={(event) =>
                                    handleImageChange(event, setFieldValue)
                                  }
                                  className={`form-control shadow-none ${
                                    errors.company_logo && touched.company_logo
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                              </div>
                            </div>
                            {errors.company_logo && touched.company_logo ? (
                              <div className="invalid-feedback ms-1 d-block">
                                {errors.company_logo}
                              </div>
                            ) : null} */}
                            {/* {values.imagePreview && (
                              <div className="mt-3">
                                <img
                                  src={values.imagePreview}
                                  alt="Company Logo"
                                  className="img-thumbnail"
                                  style={{ width: "150px", height: "150px" }}
                                />
                              </div>
                            )} */}
                          {/* </Col> */}
                        </Row>
                      </>
                    )}
                    {key === "Other Detail" && (
                      <>
                        <Row className="g-3">
                          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <label className="form-label ms-1">
                              Website
                              <span style={{ color: "grey", fontSize: "smaller", marginLeft: "5px" }}>
                                (Optional)
                              </span>
                            </label>
                            <InputGroup className="mb-3">
                            <InputGroup.Text>https://</InputGroup.Text>
                            <Field
                              name="company_website"
                              type="text"
                              className={`form-control shadow-none`}
                              placeholder="Enter Company Website"
                              onChange={handleChange}
                            />
                          </InputGroup>
                            {/* {errors.company_website && touched.company_website ? (
                              <div className="invalid-feedback ms-1 d-block">
                                {errors.company_website}
                              </div>
                            ) : null} */}
                          </Col>

                          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <label className="form-label ms-1">
                              Address
                              <span style={{ color: "grey", fontSize: "smaller", marginLeft: "5px" }}>
                                (Optional)
                              </span>
                            </label>
                            <Field
                              name="company_address"
                              type="text"
                              className={`form-control shadow-none`}
                              placeholder="Enter Address"
                              onChange={handleChange}
                            />
                            {/* {errors.company_address &&
                            touched.company_address ? (
                              <div className="invalid-feedback ms-1 d-block">
                                {errors.company_address}
                              </div>
                            ) : null} */}
                          </Col>

                          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <label className="form-label ms-1">
                              Upload Company Logo
                              <span style={{ color: "grey", fontSize: "smaller", marginLeft: "5px" }}>
                                (Optional)
                              </span>
                            </label>
                            <div className="position-relative">
                              <InputGroup className="shadow-none">
                                <Field
                                  id="company_logo"
                                  name="company_logo"
                                  type="text"
                                  readOnly
                                  className="form-control shadow-none"
                                  placeholder="Choose File"
                                  value={logoName}
                                />
                                <InputGroup.Text className="shadow-none d-flex align-items-center justify-content-center upload-ico">
                                  <ArrowUp size={19} color="#637381" weight="bold" className="cursor-pointer" />
                                </InputGroup.Text>
                              </InputGroup>
                              <input
                                type="file"
                                id="company_logo"
                                name="company_logo"
                                onChange={(event) => handleImageChange(event, setFieldValue, setFieldError, setFieldTouched)}                                
                                className="form-control shadow-none position-absolute top-0 start-0 end-0 bottom-0 opacity-0"
                              />
                              {logoName && (
                                <div
                                  className="position-absolute top-0 end-0 p-1 cursor-pointer"
                                  style={{ zIndex: 1, marginRight: "50px", marginTop:"4px" }}
                                  onClick={() => handleRemoveLogo()}
                                >
                                  <XCircle size={20} color="red" />
                                </div>
                              )}
                            </div>
                            <div style={{ color: "grey", fontSize: "smaller", marginTop: "5px" }}>
                                Note:- The logo must not exceed 250 pixels in width and 150 pixels in height.
                              </div>
                            {imageError && (
                              <div className="invalid-feedback d-block">{imageError}</div>
                            )}
                          </Col>
                        </Row>
                      </>
                    )}
                  </Tab.Content>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end  py-2 px-4 add-reseller-footer">
          <Button
            variant="link"
            className="close-btn fw-medium m-0"
            onClick={handleModalClose}
          >
            Close
          </Button>
          {key === "Company Detail" && (
            <Button
              variant="link"
              className="next-btn fw-medium m-0"
              onClick={handleResellerValidation}
              disabled={!formValid}
            >
              Next
            </Button>
          )}

          {key === "Reseller Detail" && (
            <Button
              variant="link"
              className="next-btn fw-medium m-0"
              onClick={handleOtherValidation}
              disabled={!formResellerValid}
            >
              Next
            </Button>
          )}
        
          {key === "Other Detail" && (
            <Button
              type="button"
              variant="link"
              className="next-btn fw-medium m-0"
              onClick={() => submitFormRef.current && submitFormRef.current()}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Adding...
                </>
              ) : (
                "Add"
              )}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
