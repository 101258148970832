import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Toast, Modal, ToastContainer } from "react-bootstrap";
import {
  fetchResellers,
  deleteReseller,
  toggleResellerActiveStatus,
} from "../../../store/resellerSlice";
// import View from "./View";
import { Container, Col, Row, Dropdown, ButtonGroup } from "react-bootstrap";
import PaginationComponent from "../pagination/pagination";
import ResellerDetailModal from "./ResellerDetail";
import { useLoader } from "../../../context/LoaderContext";
import moment from "moment";
import {
  MagnifyingGlass,
  ArrowUp,
  ArrowDown,
  DotsThreeVertical,
  Pencil,
  // Eye,
  Trash,
  X,
  Warning,
  CheckCircle,
} from "../../../constant/icons/icons";

export default function List({ editHandler, handleOpenModal }) {
  const { setLoading } = useLoader();
  const limit = 9;
  const dispatch = useDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [currentReseller, setCurrentReseller] = useState(null);
  // const [showViewModal, setShowViewModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [showResellerDetailModal, setShowResellerDetailModal] = useState(false);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const { total, resellers } = useSelector((state) => ({
    total: state.reseller.total,
    resellers: state.reseller.resellers,
  }));
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [resellerToDelete, setResellerToDelete] = useState({});
  const [showToggleConfirmation, setShowToggleConfirmation] = useState(false);
  const [resellerToToggle, setResellerToToggle] = useState({});
  const [toggleStatus, setToggleStatus] = useState(null);
  const [openDropdown, setOpenDropdown] = useState({ id: null, type: null });

  // const selectResellerState = (state) => state.reseller;

  // const selectResellerData = createSelector(
  //   [selectResellerState],
  //   (reseller) => {
  //     return {
  //       total: reseller.total,
  //       resellers: reseller.resellers
  //     };
  //   }
  // );

  const toastMessage = localStorage.getItem("toastMessage");
  
  useEffect(() => {
    if (toastMessage) {
      setShowSnackbar(true);
      setSnackbarMessage(toastMessage);
      setSnackbarVariant("success");
      localStorage.removeItem("toastMessage");

      if (toastMessage === "Validation failed") {
        setShowSnackbar(true);
        setSnackbarMessage(toastMessage);
        setSnackbarVariant("danger");
        localStorage.removeItem("toastMessage");
      }
    }
  }, [toastMessage]);
  // console.log(resellers);
  // const { total, resellers } = useSelector(selectResellerData); 

  /* useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }else{
      dispatch(
        fetchResellers({ offset: currentPage, limit, search: searchTerm, sort_by_key: sortKey, sort_by_value: sortOrder })
      );
    }
  }, [dispatch, currentPage, limit, searchTerm, sortKey, sortOrder]); */

  const getResellerList = useCallback(() => {
    setLoading(true);
    Promise.all([
      dispatch(
        fetchResellers({
          offset: currentPage,
          limit,
          search: searchTerm,
          sort_by_key: sortKey,
          sort_by_value: sortOrder,
        })
      ),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    dispatch,
    currentPage,
    limit,
    searchTerm,
    sortKey,
    sortOrder,
    setLoading,
  ]);

  useEffect(() => {
    if (!showResellerDetailModal) {
      getResellerList();
    }
  }, [getResellerList, showResellerDetailModal]);

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
      role="button"
    >
      {children}
    </div>
  ));

  CustomToggle.displayName = "CustomToggle";

  const handleRowClick = (reseller) => {
    setSearchTerm("");
    setCurrentPage(0);
    setCurrentReseller(reseller);
    setShowResellerDetailModal(true);
    setOpenDropdown({ id: null, type: null });
  };

  const handleDropdownToggle = (id, type) => {
    if (openDropdown.id === id && openDropdown.type === type) {
      // Close the currently open dropdown
      setOpenDropdown({ id: null, type: null });
    } else {
      // Open the new dropdown and close any others
      setOpenDropdown({ id: id, type: type });
    }
  };

  const ActionMenu = ({ reseller, isOpen, toggleMenu }) => (
    <Dropdown
      drop="up"
      as={ButtonGroup}
      className="action-dropdown w-100 d-inline-block"
      show={isOpen}
      onToggle={() => handleDropdownToggle(reseller.id, "action")}
    >
      <Dropdown.Toggle
        id={`dropdown-split-basic-${reseller.id}`}
        className="p-0"
        variant="link"
        as={CustomToggle}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggleMenu(reseller.id);
        }}
      >
        <DotsThreeVertical size={17} color="#637381" weight="bold" />
      </Dropdown.Toggle>
      <Dropdown.Menu align="end" className="action-dr-menu z-1">
        <Dropdown.Item
          eventKey="1"
          className="d-flex align-items-center dr-item item-orange"
          onClick={(e) => {
            e.stopPropagation();
            editHandler(reseller.id);
          }}
        >
          <span>
            <Pencil size={15} color="#637381" weight="regular" />
          </span>
          <p className="mb-0">Edit</p>
        </Dropdown.Item>
        {/* <Dropdown.Item
          eventKey="2"
          className="d-flex align-items-center dr-item item-green"
          onClick={() => {
            handleRowClick(reseller);
            toggleMenu(null); // Close the menu after action
          }}
        >
          <span>
            <Eye size={15} color="#637381" weight="regular" />
          </span>
          <p className="mb-0">View</p>
        </Dropdown.Item> */}
        <Dropdown.Item
          eventKey="3"
          className="d-flex align-items-center dr-item item-red"
          onClick={(e) => {
            e.stopPropagation();
            promptDeleteReseller(reseller);
            toggleMenu(null); // Close the menu after action
          }}
        >
          <span>
            <Trash size={15} color="#637381" weight="regular" />
          </span>
          <p className="mb-0">Delete</p>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const handleSort = (key) => {
    const isAsc = sortKey === key && sortOrder === "asc";
    setSortKey(key);
    setSortOrder(isAsc ? "desc" : "asc");
  };

  const renderSortIcons = (key) => {
    const isActive = sortKey === key;
    return (
      <>
        {/* <ArrowUp
          size={16}
          onClick={() => handleSort(key, "asc")}
          style={{
            cursor: "pointer",
            color: isActive && sortOrder === "asc" ? "black" : "#ccc",
          }}
        /> */}

        {/* Custom Icons */}
        <ArrowUp
          size={14}
          color={isActive && sortOrder === "asc" ? "#757779" : "#ccc"}
          className="cursor-pointer"
          weight="fill"
          onClick={() => handleSort(key, "asc")}
        />

        {/* <ArrowDown
          size={16}
          onClick={() => handleSort(key, "desc")}
          style={{
            cursor: "pointer",
            color: isActive && sortOrder === "desc" ? "black" : "#ccc",
          }}
        /> */}

        {/* Custom Icons */}
        <ArrowDown
          size={14}
          color={isActive && sortOrder === "desc" ? "#757779" : "#ccc"}
          className="cursor-pointer"
          weight="fill"
          onClick={() => handleSort(key, "desc")}
        />
      </>
    );
  };

  const promptDeleteReseller = (reseller) => {
    setResellerToDelete(reseller);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteReseller = async () => {
    if (resellerToDelete) {
      try {
        await dispatch(deleteReseller(resellerToDelete.id));
        setSnackbarMessage("Reseller deleted successfully");
        setSnackbarVariant("success");
        dispatch(
          fetchResellers({
            offset: currentPage * limit,
            limit,
            search: searchTerm,
          })
        );
      } catch (error) {
        setSnackbarMessage("Error deleting reseller");
        setSnackbarVariant("danger");
      } finally {
        setShowSnackbar(true);
        setShowDeleteConfirmation(false);
        setResellerToDelete(null);
      }
    }
  };

  // const promptToggleConfirmation = (reseller, status) => {
  //   setResellerToToggle(reseller);
  //   setToggleStatus(status);
  //   setShowToggleConfirmation(true);
  // };

  const handleConfirmToggleActive = () => {
    if (resellerToToggle) {
      dispatch(
        toggleResellerActiveStatus({
          id: resellerToToggle.id,
          isActive: toggleStatus.toString(),
        })
      )
        .then(() => {
          setSnackbarMessage(
            `Reseller ${
              toggleStatus ? "activated" : "deactivated"
            } successfully`
          );
          setSnackbarVariant("success");
          getResellerList();
        })
        .catch((error) => {
          setSnackbarMessage("Failed to update reseller status");
          setSnackbarVariant("danger");
        })
        .finally(() => {
          setShowSnackbar(true);
          setShowToggleConfirmation(false);
          setResellerToToggle(null);
          setToggleStatus(null);
        });
    }
  };

  // const StatusDropdown = ({ reseller, isOpen, toggleDropdown }) => (
  //   <Dropdown
  //     className="status-dropdown"
  //     show={isOpen}
  //     onToggle={() => handleDropdownToggle(reseller.id, "status")}
  //   >
  //     <Dropdown.Toggle
  //       className="p-0"
  //       variant="link"
  //       id={`dropdown-basic-${reseller.id}`}
  //       onClick={(e) => {
  //         e.stopPropagation();
  //         toggleDropdown(reseller.id);
  //       }}
  //     >
  //       {reseller.is_active ? (
  //         <div className="d-flex align-items-center gap-1 active-btn text-capitalize rounded-pill">
  //           <p className="mb-0">Active</p>
  //         </div>
  //       ) : (
  //         <div className="d-flex align-items-center gap-1 in-active-btn text-capitalize rounded-pill">
  //           <p className="mb-0">In Active</p>
  //         </div>
  //       )}
  //     </Dropdown.Toggle>
  //     <Dropdown.Menu className="dr-menu z-1">
  //       <Dropdown.Item
  //         className="dr-menu-item"
  //         onClick={(e) => {
  //           e.preventDefault();
  //           e.stopPropagation();
  //           promptToggleConfirmation(reseller, !reseller.is_active);
  //           toggleDropdown(null);
  //         }}
  //       >
  //         Set {reseller.is_active ? "In-Active" : "Active"}
  //       </Dropdown.Item>
  //     </Dropdown.Menu>
  //   </Dropdown>
  // );

  return (
    <>
      <ToastContainer position="top-end" className=" pt-3 pe-3 cu-toster">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
        >
          <Toast.Body className="d-flex align-items-center gap-2 text-white">
            <CheckCircle size={22} color="#ffffff" weight="duotone" />
            <p className="mb-0">{snackbarMessage}</p>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Modal
        show={showDeleteConfirmation}
        onHide={() => setShowDeleteConfirmation(false)}
        dialogClassName="modal-dialog-scrollable add-reseller-popup"
        centered
      >
        <Modal.Header className="add-reseller-header d-inline-block p-0  border-bottom-0">
          <div className="d-flex align-items-center justify-content-between gap-3 br-bottom py-3 px-4">
            <h5 className="fw-semibold mb-0">Delete Reseller</h5>
            <div className="cursor-pointer rounded-circle d-flex align-items-center justify-content-center x-arrow">
              <X
                size={18}
                color=""
                weight="bold"
                onClick={() => setShowDeleteConfirmation(false)}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="py-4 delete-reseller-content">
          <div className="d-flex flex-column gap-3 justify-content-center align-items-center">
            <div className="rounded-2 d-flex align-items-center justify-content-center error-show-icon">
              <Warning size={46} color="#dc3545" weight="regular" />
            </div>
            <p className="mb-0 fw-medium text-center">
              Are you sure you want to delete <b>{resellerToDelete?.name}</b>.
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex align-items-center justify-content-center pt-2  pb-4 px-4 border-top-0 add-reseller-footer">
          <Button
            variant="link"
            className="close-btn fw-medium m-0"
            onClick={() => setShowDeleteConfirmation(false)}
          >
            Cancel
          </Button>
          <Button
            variant="link"
            className="next-btn fw-medium m-0"
            onClick={() => handleDeleteReseller()}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showToggleConfirmation}
        onHide={() => setShowToggleConfirmation(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Status Change</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to {toggleStatus ? "activate" : "deactivate"}{" "}
          <b>{resellerToToggle?.name}</b> ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowToggleConfirmation(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmToggleActive}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="bg-primary pt-10 pb-21"></div>
      <Container fluid className="mt-n22 px-6 pb-6">
        <Row>
          <Col lg={12} md={12} xs={12}>
            <div>
              <div>
                <h3 className="mb-0 fw-semibold pg-title text-white">
                  Reseller
                </h3>
              </div>
            </div>
          </Col>

          <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
            <div className="position-relative rounded-3 bg-white  tablecontainer">
              <div className="d-flex align-items-center justify-content-between gap-3 reseller-sea-btn">
              <div className="position-relative">
                <input
                type="search"
                placeholder="Search"
                className="searchbar outline-none border-0 shadow-none cursor-pointer"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setCurrentPage(0);
                }}  
              />
              <MagnifyingGlass
                size={18}
                color="#757779"
                className="position-absolute top-50 start-0 ms-4 translate-middle"
                weight="regular"
              />
            </div>
                <Button
                  variant="primary"
                  className="fw-medium"
                  onClick={() => handleOpenModal(true)}
                >
                  Add Reseller
                </Button>
              </div>

              <div className="table-responsive rounded-2 mt-5 custom_table pg-table-scroll overflow-y-auto">
                <table className="w-100">
                  <thead>
                    <tr>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0 ">Person Name</p>
                          <span
                            className="d-flex align-items-center"
                            onClick={() => handleSort("name")}
                          >
                            {renderSortIcons("name")}
                          </span>
                        </div>
                      </th>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0 ">Company Name</p>
                        </div>
                      </th>

                      {/* <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Country Code</p>
                        </div>
                      </th> */}
                      {/* <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Country</p>
                        </div>
                      </th> */}
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Company Mobile Number</p>
                        </div>
                      </th>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Company Email</p>
                          <span
                            className="d-flex align-items-center"
                            onClick={() => handleSort("email")}
                          >
                            {renderSortIcons("email")}
                          </span>
                        </div>
                      </th>
                      {/* <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Referral Code</p>
                        </div>
                      </th> */}
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Credits</p>
                          <span className="d-flex align-items-center">
                            {renderSortIcons("credits")}
                          </span>
                        </div>
                      </th>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Organization Count</p>
                          {/* <span
                            className="d-flex align-items-center"
                            onClick={() => handleSort("customer_count")}
                          >
                            {renderSortIcons("customer_count")}
                          </span> */}
                        </div>
                      </th>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Created On</p>
                          <span
                            className="d-flex align-items-center"
                            onClick={() => handleSort("created_at")}
                          >
                            {renderSortIcons("created_at")}
                          </span>
                        </div>
                      </th>
                      {/* <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Status</p>
                        </div>
                      </th> */}
                      <th className="fw-medium">
                        <div className="d-flex align-items-center justify-content-center gap-th">
                          <p className="mb-0">Action</p>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {resellers.length === 0 ? (
                      <tr>
                        <td colSpan="7" className="text-center border-bottom-0">
                          <p className="mt-5 mb-0 no-data text-capitalize">
                            No data available
                          </p>
                        </td>
                      </tr>
                    ) : (
                      resellers.map((reseller) => (
                        <tr
                          key={reseller.id}
                          className="cursor-pointer position-relative"
                          onClick={() => handleRowClick(reseller)}
                          // onClick={() => handleRowClick(reseller)}
                        >
                          <td>{reseller.name}</td>
                          <td>{reseller.company?.name}</td>
                          {/* <td>{reseller.country_code}</td> */}
                          {/* <td>{reseller?.country?.name}</td> */}
                          <td>{reseller.company?.mobile_number}</td>
                          <td>{reseller.email}</td>
                          {/* <td>{reseller.referral_code}</td> */}
                          <td>{reseller.credits}</td>
                          <td>{reseller.customer_count}</td>
                          <td>
                            {moment(reseller.created_at).format("DD/MM/YYYY hh:mm A")}
                          </td>
                          {/* <td> */}
                            {/* {reseller.is_active ? (
                              <Badge pill bg="success">
                                Active
                              </Badge>
                            ) : (
                              <Badge pill bg="danger">
                                Inactive
                              </Badge>
                            )} */}
                            {/* <StatusDropdown
                              reseller={reseller}
                              isOpen={
                                openDropdown.id === reseller.id &&
                                openDropdown.type === "status"
                              }
                              toggleDropdown={() =>
                                handleDropdownToggle(reseller.id, "status")
                              }
                            /> */}
                          {/* </td> */}
                          <td className="text-center">
                            <ActionMenu
                              reseller={reseller}
                              isOpen={
                                openDropdown.id === reseller.id &&
                                openDropdown.type === "action"
                              }
                              toggleMenu={() =>
                                handleDropdownToggle(reseller.id, "action")
                              }
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
              {total > limit && (
                <div className="pt-4">
                  <PaginationComponent
                    totalItems={total}
                    itemsPerPage={limit}
                    currentPage={currentPage + 1}
                    onPageChange={handlePageChange}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>

      {currentReseller && (
        <ResellerDetailModal
          show={showResellerDetailModal}
          editHandler={editHandler}
          handleResellerDetailClose={() => setShowResellerDetailModal(false)}
          reseller={currentReseller}
          handleOpenModal={handleOpenModal}
          handleListClose={() => setShowResellerDetailModal(false)}
        />
      )}

      {/* {currentReseller && (
        <View
          show={showViewModal}
          handleClose={() => setShowViewModal(false)}
          reseller={currentReseller}
        />
      )} */}
    </>
  );
}
